import image1 from "../assets/PASTA.png";

const desert = [
    { 
      id: 1, 
      category: 'DESERET', 
      title: "ËMBËLSIRË", 
      image: image1, 
      price: "3.50"
    },
  ];

export { desert }  