import image1 from "../assets/PASTA.png";

const items = [
    // { 
    //   id: 1, 
    //   text: 'See More', 
    //   title: "CAFE MENU", 
    //   subItems: ["Espresso, Macchiato, Americano, Cappucino, Flat White, French Cafe, Latte, Iced Cafe, Iced Late, Irish Cafe, Hot Chocolate, Tea"], 
    //   image: image1, 
    //   link: "/caffe" 
    // },

   
    { 
      id: 3, 
      text: 'See More', 
      title: "BREAKFAST", 
      subItems: ["Croissant", "Avokado Tost", "Royal Eggs", 'Mëngjesi Epoque', 'Omletë Mediterrane', "Sweet French Toast", "AVOCADO POACHED EGGS"], 
      image: image1, 
      link: "/breakfast" 
    },

    { 
      id: 4, 
      text: 'See More',
      title: "STARTERS", 
      subItems: ["Cheese sticks", "Brusketa Klasike", "Brusketa me salmon të tymosur", 'Këpurdha në Furrë', 'Djath në Furrë', "Tacos me karkalec", "Karkalec në kadaif", "Chicken fingers", "Meze e ftohtë","Meze e ngrohtë "], 
      image: image1, 
      link: "/starters" 
    },

    { 
      id: 5, 
      text: 'See More', 
      title: "SALADS", 
      subItems: ["Sallatë Iceberg", "Sallatë Baby Spinaq", "Sallatë Crunchy", "Sallatë Caprese", "Sallatë Caesar", "Sallatë salmon"], 
      image: image1, 
      link: "/sallata" 
    },

    { 
      id: 6, 
      text: 'See More', 
      title: "Pasta & Risotto", 
      subItems: ["Tagliatelle Caffè & Formaggio", "Penne me Pulë dhe Spinaq", "Penne al Pesto", "Paccheri alla Sorrentina", "Linguini me Karkaleca", "Penne me Salmon & Brokoli", "Tagliatelle Amatriciana", "Risotto Primavera", "Risotto Gorgonzola & Kërpudha ", "Risotto Pulë & Spinaq", "Risotto Karkaleca dhe kepurdha", "Risotto me Mish Viqi"], 
      image: image1, 
      link: "/pasta" 
    },

    { 
      id: 7, 
      text: 'See More', 
      title: "Burgers & Sandwiches", 
      subItems: ["Cheese Burger", "Crunchy Burger", "Mozarella Baguette", "Steak Baguette "], 
      image: image1, 
      link: "/burgers-sandwiches" 
    },

    { 
      id: 8, 
      text: 'See More', 
      title: "Epoque Dishes", 
      subItems: ["Pulë Masala & Tagliatelle · Pulë Crunchy & Tagliatelle · Pulë Crunchy & Risotto · Epoque Wrap · Pulë Mediterane me Oriz dhe Mozarella · Pulë Sauté me Spinaq · Karkaleca në Tavë · Mish Viçi me Këpurdha · Mish Viçi në Furrë · Mish Viçi me Pure Spinaqi"], 
      image: image1, 
      link: "/epoque-dishes" 
    },
    { 
      id: 2, 
      text: 'See More', 
      title: "KOKTEJ", 
      subItems: ["Mojito, Strawberry Mojito, Margarita, Cosmopolitan, Espresso Martini, French 75, Champs Elysees, Aperol Spritz, Vodka Passion, Whiskey Sour, Negroni, Black Russian, Americano, Sex on the Beach, Long Island Ice Tea, Japanese Ice Tea, Blue Hawaiian, Bahama Mama, Green Cocktail,Pina Colada "], 
      image: image1, 
      link: "/koktej" 
    },

    { 
      id: 9, 
      text: 'See More', 
      title: "Desert", 
      subItems: ["Ëmbëlsirë"], 
      image: image1, 
      link: "/desert" 
    },

  ];

export { items }  